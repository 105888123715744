import React from 'react';
// reactstrap components
import { Col, Container, Row } from 'reactstrap';

// core components

function CompleteExamples() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">Completed with examples</h2>
              <h5 className="description">
                The kit comes with three pre-built pages to help you get started faster. You can
                change the text and images and you`re good to go. More importantly, looking at them
                will give you a picture of what you can built with this powerful Bootstrap 4 ui kit.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CompleteExamples;
